import { Box, Button, MenuItem, Stack, Typography, styled, useTheme } from '@mui/material';
import {
  ACCOUNT_MANAGEMENT_TAB_INDEX,
  AccountManagementPageParam,
  AlignTable,
  HeadCell,
  NoDataAvailable,
  RoleId,
  RowMenu,
  Table,
  checkNoData,
  themeLevel5,
  useUserSessionAPI,
} from 'common-components';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageURL } from '../../../navbar/constants';
import { WriteAccessConnection } from '../../selectors';
import { AudienceScheduleForm, ScheduleFrequency } from '../../types';
import { Layout } from './layout';

export const MenuContainer = styled(Box)<{ selected?: boolean }>(({ selected, theme }) => ({
  width: 'fit-content',
  '> button, > button * ': {
    borderColor: selected ? theme.palette.background.default : 'unset',
    color: selected ? theme.palette.background.default : 'unset',
  },
}));

interface ScheduleMenuProps {
  onDelete: () => void;
  onEdit: () => void;
  disableEdit: boolean;
}
const ScheduleMenu = ({ onDelete, onEdit, disableEdit }: ScheduleMenuProps) => {
  const { t } = useTranslation('audience');
  return (
    <MenuContainer data-testid={'scheduleitem-menu'} onClick={(e: any) => e.stopPropagation()}>
      <RowMenu>
        <MenuItem disabled={disableEdit} onClick={onEdit}>
          {t('menu.editSchedule')} {disableEdit && `(${t('schedule.connection_disabled')})`}
        </MenuItem>
        <MenuItem onClick={onDelete}>{t('menu.deleteSchedule')}</MenuItem>
      </RowMenu>
    </MenuContainer>
  );
};

type ScheduleListProps = {
  schedules?: AudienceScheduleForm[];
  addPermission: boolean;
  connections: WriteAccessConnection[];
  onDelete: (schedule: AudienceScheduleForm) => void;
  onAdd: () => void;
  onEdit: (schedule: AudienceScheduleForm) => void;
};
export function ScheduleList({ schedules, onDelete, onAdd, onEdit, addPermission, connections }: Readonly<ScheduleListProps>) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t: tAudience } = useTranslation('audience');
  const { t: tTranslation } = useTranslation('translation');
  const noData = useMemo(() => checkNoData(schedules), [schedules]);
  const [{ data: userSession }] = useUserSessionAPI();
  const isConnectionDisabled = checkNoData(connections);
  const isManager = userSession?.roleId === RoleId.Manager;

  const columns = useMemo<HeadCell<AudienceScheduleForm>[]>(
    () => [
      {
        title: tAudience('detail.destination'),
        dataIndex: 'connectionName',
        render: (_, row: AudienceScheduleForm) => row.connectionName,
      },
      {
        title: tAudience('detail.frequency'),
        dataIndex: 'frequency',
        render: (_, { frequency, day, time, nextFireTime, lastRunTime }) => (
          <>
            {frequency === ScheduleFrequency.OneTime && !nextFireTime
              ? tAudience('scheduleUpdate.noSchedule')
              : tAudience('schedule.time', {
                  context: frequency,
                  day: day,
                  time: moment(time, 'HH:mm').format('hh:mm A'),
                })}
            {lastRunTime && (
              <>
                &nbsp;
                <Typography variant="body4">
                  ({tAudience('scheduleUpdate.last')}&nbsp;
                  {tTranslation('dateTimeFormat.shortDate', { val: moment(lastRunTime).toDate() })})
                </Typography>
              </>
            )}
          </>
        ),
      },
      {
        title: '',
        dataIndex: 'audienceId',
        align: 'right' as AlignTable,
        render: (_, schedule) => (
          <ScheduleMenu
            disableEdit={!connections.find((conn) => conn.id === schedule.connectionId)}
            onDelete={() => {
              onDelete(schedule);
            }}
            onEdit={() => onEdit(schedule)}
          />
        ),
      },
    ],
    [onDelete, onEdit, tAudience, tTranslation, connections]
  );

  const renderNoData = () => {
    const params = new URLSearchParams(window.location.search);
    params.append(AccountManagementPageParam.DEFAULT_TAB, ACCOUNT_MANAGEMENT_TAB_INDEX.CONNECTIONS);
    const openOrgConnectionsPage = () => {
      navigate(
        { pathname: PageURL.AccountManagement, search: params.toString() },
      );
    };

    return (
      <Stack alignItems={'center'}>
        <Stack sx={({ typography }) => ({ maxWidth: typography.pxToRem(300) })} rowGap={7.75}>
          <NoDataAvailable
            description={isConnectionDisabled ? 'detail.connectionMustBeEnabled' : 'detail.noScheduleAvailable'}
            ns="audience"
            sx={{ fontSize: theme.typography.body3 }}
          />
          {isManager && isConnectionDisabled && (
            <Button variant="outlined" color="inherit" onClick={openOrgConnectionsPage}>
              {tAudience('detail.manageConnections')}
            </Button>
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <Layout
      sx={{
        '.body__container': {
          justifyContent: 'center',
        },
      }}
      header={
        <>
          <Typography variant="subtitle2">{tAudience('detail.schedule')}</Typography>
          <Button
            variant="outlined"
            color="inherit"
            onClick={onAdd}
            sx={{
              minWidth: themeLevel5.typography.pxToRem(75),
              height: '1.5rem',
              borderRadius: themeLevel5.typography.pxToRem(themeLevel5.shape.borderRadius / 2),
            }}
            disabled={!addPermission}
          >
            {tTranslation('button.add')}
          </Button>
        </>
      }
      body={
        noData ? (
          renderNoData()
        ) : (
          <Table
            columns={columns}
            dataSources={schedules}
            tableContainerProps={{
              sx: { flex: 1, flexBasis: 0, color: 'inherit' },
            }}
            tableProps={{ stickyHeader: true }}
          />
        )
      }
    ></Layout>
  );
}
