import { UseAPI, useUserSessionAPI } from 'common-components';
import _ from 'lodash';
import { useCallback } from 'react';
import { DateSelectorDependency, useDateSelectorBindingAPI } from '../../../selectors';
import { DateRangeStateEnum } from '../../../state/app/appSlice';
import { getMetricsArray } from '../constants';
import { useCampaignState, useLookupCampaignsAPI, useMetricTypeState } from '../selectors';
import { CampaignType, DefaultFilter } from '../types';

export enum DemographicType {
  Age = 'age',
  Gender = 'gender',
  Income = 'income',
  Location = 'location',
  Married = 'married',
  Homeowner = 'homeowner',
  Networth = 'networth',
}

interface Insight {
  demographicType: DemographicType;
  insightText: string;
}

export interface InsightResponse {
  campaigns: CampaignType[];
  events: string[];
  results: Insight[];
}

export const useInsightAPI = (): UseAPI<InsightResponse> => {
  const [userSessionData] = useUserSessionAPI();
  const [campaigns] = useLookupCampaignsAPI();
  const [campaignIds] = useCampaignState();
  const [metricType] = useMetricTypeState();

  const [insightsData, fetchInsightsData, ...rest] = useDateSelectorBindingAPI<InsightResponse>({
    depend: DateSelectorDependency.AnalyticsInsight_B2C,
    params: {
      organizationId: userSessionData.data?.orgId,
    },
    convertDateToUTC: true,
    method: 'POST',
    dateRangeState: DateRangeStateEnum.Campaign360,
  });

  const doFetchData = useCallback(() => {
    const events = getMetricsArray(metricType);
    if (events && campaignIds?.length && campaigns.data?.length) {
      return fetchInsightsData(
        {
          events,
          campaigns: _.includes(campaignIds, DefaultFilter.All) ? _.map(campaigns.data, 'id') : campaignIds,
        },
        true
      );
    }
  }, [campaignIds, campaigns.data, fetchInsightsData, metricType]);

  return [insightsData, doFetchData, ...rest];
};
