import { useParams } from 'react-router-dom';
import { useUserSessionAPI } from '../selectors';
import { useAPI } from '../state';
var GET_TROVO_CONNECTION_DETAIL_API = '/api/connection/trovo/{orgId}';
var GET_ALL_CONNECTION_TYPE_API = '/api/connection/allConnectionType?orgId={orgId}&planId={planId}';
var CREATE_CONNECTION_API = '/api/connection';
var GET_OAUTH2_MAILCHIMP_API = '/api/connection/mailchimp/oauth-url';
var GET_OAUTH2_GOOGLE_API = '/api/connection/google/oauth-url/{orgId}';
var TEST_CONNECTION_API = '/api/connection/test';
export var ConnectionStatus;
(function (ConnectionStatus) {
    ConnectionStatus[ConnectionStatus["Enabled"] = 1] = "Enabled";
    ConnectionStatus[ConnectionStatus["Disabled"] = 0] = "Disabled";
    ConnectionStatus[ConnectionStatus["Pending"] = 2] = "Pending";
})(ConnectionStatus || (ConnectionStatus = {}));
export var useTrovoConnectionAPI = function () {
    var _a;
    var _b = useParams().orgId, orgId = _b === void 0 ? '' : _b;
    var userSessionData = useUserSessionAPI()[0];
    return useAPI(GET_TROVO_CONNECTION_DETAIL_API, {
        orgId: orgId || ((_a = userSessionData.data) === null || _a === void 0 ? void 0 : _a.orgId),
    });
};
export var useConnectionTypeAPI = function (orgId, planId) {
    return useAPI(GET_ALL_CONNECTION_TYPE_API, {
        orgId: orgId,
        planId: planId,
    });
};
export var useCreateOrUpdateConnectionAPI = function () {
    return useAPI(CREATE_CONNECTION_API, undefined, 'POST');
};
export var useOAuth2Mailchimp = function () {
    return useAPI(GET_OAUTH2_MAILCHIMP_API);
};
export var useOAuth2Google = function (orgId) {
    return useAPI(GET_OAUTH2_GOOGLE_API, {
        orgId: orgId,
    }, 'POST');
};
export var useTestConnectionAPI = function () {
    return useAPI(TEST_CONNECTION_API, undefined, 'POST');
};
