import { FormControl, FormHelperText, GridProps, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import { Card, CardContent, Select, useForm } from 'common-components';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListByConnection, WriteAccessConnection } from '../../selectors';
import { AudienceScheduleForm, ScheduleFrequency } from '../../types';
import { NEW_LIST_VALUE, Option, generateOptions } from './const';
import { DateTimeInputs } from './daterangeinputs';

type InputProps = {
  name: keyof AudienceScheduleForm;
  label: string;
  inputType?: 'select' | 'dateinputs';
  gridProps: GridProps;
  options: Option[];
  onSelect?: (value: unknown) => void;
};
type State = {
  frequency: ScheduleFrequency;
  connections: Array<WriteAccessConnection>;
  lists?: Array<ListByConnection>;
};
type Handler = {
  registerField: ReturnType<typeof useForm>['registerField'];
  setFieldTouched: ReturnType<typeof useForm>['setFieldTouched'];
  setFieldValue: ReturnType<typeof useForm>['setFieldValue'];
};
export function useFormInputs(states: State, handler: Handler) {
  const { frequency, connections, lists } = states;
  const { registerField, setFieldTouched, setFieldValue } = handler;
  const { t } = useTranslation(['audience', 'translation']);
  const inputs = useMemo(() => {
    const daySelect = (() => {
      switch (frequency) {
        case ScheduleFrequency.Monthly:
          return {
            label: t('schedule.day_of_month'),
            options: generateOptions('daysOfMonth'),
          };
        case ScheduleFrequency.Weekly:
          return {
            label: t('schedule.day_of_week'),
            options: generateOptions('daysOfWeek', { format: (opt) => ({ ...opt, name: t(`schedule.${opt.name}`) }) }),
          };
        case ScheduleFrequency.Daily:
          return {
            label: t('schedule.days'),
            options: generateOptions('days', { format: (opt) => ({ ...opt, name: t(`schedule.${opt.name}`) }) }),
          };
        default:
          return null;
      }
    })();

    return [
      {
        name: 'connectionId',
        label: t('schedule.connection'),
        gridProps: {
          xs: 6,
        },
        options:
          connections?.map((conn) => ({
            id: conn.id,
            name: conn.connectionName,
          })) || [],
        onSelect: () => {
          setFieldValue(null, 'list');
          setFieldTouched(false, 'list');
        },
      },
      {
        name: 'frequency',
        label: t('schedule.frequency'),
        gridProps: {
          xs: 6,
        },
        options: generateOptions('frequency', { format: (val) => ({ ...val, name: t(`schedule.${val.name}`) }) }),
      },
      ...(daySelect
        ? [
            {
              name: 'day',
              label: daySelect.label,
              options: daySelect.options,
              gridProps: {
                xs: true,
              },
            },
          ]
        : []),
      {
        name: 'time',
        label: t('schedule.time'),
        gridProps: {
          xs: true,
        },
        options: generateOptions('times'),
      },
      {
        name: 'scope',
        label: t('schedule.scope'),
        gridProps: {
          xs: 12,
        },
        inputType: frequency === ScheduleFrequency.OneTime ? 'dateinputs' : undefined,
        options: generateOptions('scope', {
          format: (val) => ({ ...val, name: t(`schedule.${val.name}`) }),
          frequency,
        }),
      },
      {
        name: 'list',
        label: t('schedule.list'),
        gridProps: {
          xs: 12,
        },
        options: (() => {
          const options = [{ id: NEW_LIST_VALUE, name: `+ ${t('schedule.new_list')}` }];
          return lists && lists.length > 0
            ? options.concat(
                ...lists.map((list) => ({
                  id: list.id,
                  name: list.name,
                }))
              )
            : options;
        })(),
      },
    ] as InputProps[];
  }, [frequency, connections, lists, t, setFieldValue, setFieldTouched]);

  const renderInput = useCallback(
    (input: InputProps) => {
      const inputProps = registerField(input.name);
      const label = <InputLabel>{input.label}</InputLabel>;

      if (input.options.length > 0 && !inputProps.value) {
        // Auto select default value if the value is empty
        const defaultValue = input.options[0].id;
        setFieldValue(defaultValue, inputProps.name);
      }

      if (input.inputType === 'dateinputs') {
        return (
          <>
            {label}
            <Card sx={{ border: '1px solid #FFFFFFAD', borderRadius: '10px' }} disabledShadow>
              <CardContent>
                <DateTimeInputs registerField={registerField} />
              </CardContent>
            </Card>
          </>
        );
      }

      return (
        <>
          {label}
          <FormControl fullWidth>
            <Select
              name={inputProps.name}
              value={inputProps.value}
              error={!!inputProps.error}
              MenuProps={{ sx: (theme) => ({ maxHeight: theme.typography.pxToRem(320) }) }}
              inputRef={inputProps.inputRef}
              onChange={(e: SelectChangeEvent<unknown>) => {
                input.onSelect?.(e.target.value);
                inputProps.onChange(e);
                setFieldTouched(true, inputProps.name);
              }}
              onBlur={inputProps.onBlur}
            >
              {input.options.map((opt) => (
                <MenuItem key={opt.id} value={opt.id}>
                  {opt.name}
                </MenuItem>
              ))}
            </Select>
            {inputProps.error && <FormHelperText error>{inputProps.helperText}</FormHelperText>}
          </FormControl>
        </>
      );
    },
    [registerField, setFieldTouched, setFieldValue]
  );

  return {
    inputs,
    renderInput,
  };
}
