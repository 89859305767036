import {
  GeoLocationDetailExportColumns,
  GroupAnalysisExportColumns,
  RegionLocationDetailExportColumns,
  StatSummaryExportColumns,
} from './constants';

export enum DefaultFilter {
  All = '**all**',
}

export enum GroupBy {
  GenderAge = 'genderAge',
  Gender = 'gender',
  Age = 'age',
  Income = 'income',
}

export enum LocationDetailMode {
  Regions = 'regions',
  Geography = 'geography',
}

export enum LDetail {
  Regions = 'regions',
  Geography = 'demographic.geography',
}

export enum Gender {
  Female = 'F',
  Male = 'M',
  Unknown = 'U',
}

export enum GroupAnalysisFieldValue {
  Value1 = 'value1',
  Value2 = 'value2',
}

export enum MetricType {
  Emails = 'Emails',
  Checkouts = 'Checkouts',
  Unsubscribes = 'Unsubscribes',
  MarkedSpam = 'Marked Spam',
  Bounces = 'Bounces',
  OptOuts = 'Opt-Outs',
}

export interface ProfileType {
  gender: string | null;
  age: string | null;
  income: string | null;
  region: string | null;
  married: boolean | null;
  homeowner: boolean | null;
  networth: string | null;
}
export interface ProfileStatsType {
  percentOfTotal: number;
  segmentSize: number;
  liftOverAverage: number;
  responseRate: number;
  sortScore: number;
}
export interface CampaignType {
  id: number;
  name: string;
}
export interface RpeB2cResultsType {
  profile: ProfileType;
  profileStats: ProfileStatsType;
}
export type ResponseProfileExporers = {
  campaigns: CampaignType[];
  events: string[];
  results: RpeB2cResultsType[];
};

export type SlatSummaryExport = Record<typeof StatSummaryExportColumns[number], any>;
export type GroupAnalysisExport = Record<typeof GroupAnalysisExportColumns[number], any>;
export type GeoLocationDetailExport = Record<typeof GeoLocationDetailExportColumns[number], any>;
export type RegionLocationDetailExport = Record<typeof RegionLocationDetailExportColumns[number], any>;

export enum EXCEL_COLUMN_RPE {
  StartDate = 'Start Date',
  EndDate = 'End Date',
  Campaign = 'Campaign',
  Event = 'Event',
  _Gender = 'Gender',
  Age = 'Age',
  Income = 'Income',
  City = 'City',
  State = 'State',
  Zip = 'Zip',
  Region = 'Region',
  Married = 'Married',
  Homeowner = 'Homeowner',
  Networth = 'Networth',
  SegmentSize = 'Segment Size',
  ResponseRate = 'Response Rate',
  LiftOverAverage = 'Lift over Average',
}

export enum EXCEL_COLUMN_INSIGHTS {
  StartDate = 'Start Date',
  EndDate = 'End Date',
  Campaign = 'Campaign',
  ResponseType = 'Response Type',
  Demographic = 'Demographic (Feature)',
  Insight = 'Insight',
}
