import * as yup from 'yup';
export var validationSchema = function (t) {
    return yup.object().shape({
        apiKey: yup
            .string()
            .trim()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label('API Key'),
        status: yup
            .number()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label('Status'),
    });
};
export var TAG_TAB_INDEX;
(function (TAG_TAB_INDEX) {
    TAG_TAB_INDEX["STANDARD"] = "Standard";
    TAG_TAB_INDEX["CUSTOM"] = "Custom";
})(TAG_TAB_INDEX || (TAG_TAB_INDEX = {}));
export var validateMetaConnectionSchema = function (t) {
    return yup.object().shape({
        accessToken: yup
            .string()
            .trim()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label('Access Token'),
        adsAccountId: yup
            .string()
            .trim()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label('Ads Account ID'),
        status: yup
            .number()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label('Status'),
    });
};
export var validateXAdsConnectionSchema = function (t) {
    return yup.object().shape({
        consumerKey: yup
            .string()
            .trim()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label(t('xAdsConnectorDialog.consumerKey')),
        consumerSecret: yup
            .string()
            .trim()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label(t('xAdsConnectorDialog.consumerSecret')),
        accessToken: yup
            .string()
            .trim()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label(t('xAdsConnectorDialog.accessToken')),
        accessTokenSecret: yup
            .string()
            .trim()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label(t('xAdsConnectorDialog.accessTokenSecret')),
        adsAccountId: yup
            .string()
            .trim()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label(t('xAdsConnectorDialog.adsAccountId')),
        status: yup
            .number()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label(t('xAdsConnectorDialog.status')),
    });
};
export var validateGoogleAdsConnectionSchema = function (t) {
    return yup.object().shape({
        adsAccountId: yup
            .string()
            .trim()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label(t('googleAdsConnectorDialog.adsAccountId')),
        clientId: yup
            .string()
            .trim()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label(t('googleAdsConnectorDialog.clientId')),
        clientSecret: yup
            .string()
            .trim()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label(t('googleAdsConnectorDialog.clientSecret')),
        developerToken: yup
            .string()
            .trim()
            .nullable()
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label(t('googleAdsConnectorDialog.developerToken')),
        status: yup
            .number()
            .nullable()
            .oneOf([0, 1])
            .required("".concat(t('error.required', { ns: 'translation' })))
            .label(t('status')),
    });
};
var EMAIL_BUSINESS_TYPE = {
    displayName: 'Email',
    color: '#FFFFFF',
    bgColor: '#5477B0',
    fontSize: '0.87rem',
};
var WEB_TRAFFIC_BUSINESS_TYPE = {
    displayName: 'Web Traffic',
    color: '#272727',
    bgColor: '#90E0D1',
    fontSize: '0.87rem',
};
var PAID_BUSINESS_TYPE = {
    displayName: 'Paid / Ad',
    color: '#272727',
    bgColor: '#62B5E5',
    fontSize: '0.87rem',
};
var OTHER_BUSINESS_TYPE = {
    displayName: 'Other',
    color: '#FFFFFF',
    bgColor: '#BB006B',
    fontSize: '0.87rem',
};
export var CONNECTION_BUSINESS_TYPE = {
    trovo: WEB_TRAFFIC_BUSINESS_TYPE,
    klaviyo: EMAIL_BUSINESS_TYPE,
    mailchimp: EMAIL_BUSINESS_TYPE,
    sendgrid: EMAIL_BUSINESS_TYPE,
    meta: PAID_BUSINESS_TYPE,
    x_ads: PAID_BUSINESS_TYPE,
    googleAds: PAID_BUSINESS_TYPE,
    other: OTHER_BUSINESS_TYPE,
};
