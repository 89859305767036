var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyAccessValue } from '../enum';
import { useForm } from '../hooks';
import { ConnectionConfigDialog } from './connectionConfigDialog';
import { validationSchema } from './constants';
import { ConnectionStatus } from './selectors';
export var APIKeyDialog = function (_a) {
    var open = _a.open, connectionData = _a.connectionData, enableAccessConfig = _a.enableAccessConfig, connectionBusinessType = _a.connectionBusinessType, props = __rest(_a, ["open", "connectionData", "enableAccessConfig", "connectionBusinessType"]);
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var _b = useForm(useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        return ({
            initialValues: {
                apiKey: (_c = (_b = (_a = connectionData[0]) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.apiKey) !== null && _c !== void 0 ? _c : '',
                status: (_e = (_d = connectionData[0]) === null || _d === void 0 ? void 0 : _d.status) !== null && _e !== void 0 ? _e : ConnectionStatus.Enabled,
                access: (_g = (_f = connectionData[0]) === null || _f === void 0 ? void 0 : _f.access) !== null && _g !== void 0 ? _g : KeyAccessValue.Write,
            },
            validationSchema: validationSchema(t),
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t])), values = _b.values, isDirty = _b.isDirty, errors = _b.errors, getError = _b.getError, handleChange = _b.handleChange, handleSubmit = _b.handleSubmit, handleReset = _b.handleReset, handleBlur = _b.handleBlur;
    var onSubmit = handleSubmit(function (castedValues) {
        var _a;
        props.onSubmit(__assign(__assign({}, connectionData[0]), { orgId: (_a = props.orgId) !== null && _a !== void 0 ? _a : '', details: {
                apiKey: castedValues.apiKey,
            }, status: castedValues.status, access: castedValues.access, connectionTypeId: props.connectionTypeId }));
    });
    var onCancel = function () {
        var _a;
        handleReset();
        (_a = props.onCancel) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    return (_jsx(ConnectionConfigDialog, { open: open, enableAccessConfig: enableAccessConfig, connectionBusinessType: connectionBusinessType, requesting: props.requesting, externalError: props.externalError, provider: props.provider, formStates: { values: values, isDirty: isDirty, errors: errors, handleChange: handleChange, handleBlur: handleBlur, getError: getError }, inputs: [
            {
                key: 'apiKey',
                label: t('apiKeyDialog.apiKey'),
                type: 'password',
            },
        ], onSubmit: onSubmit, onCancel: onCancel, connectionData: connectionData === null || connectionData === void 0 ? void 0 : connectionData[0], connectionTypeId: props.connectionTypeId, orgId: props.orgId }));
};
