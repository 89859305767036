import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  Avatar,
  Backdrop,
  Box,
  BoxProps,
  Button,
  IconButton,
  IconButtonProps,
  Paper,
  Popper,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  iconButtonClasses,
  styled,
} from '@mui/material';
import { FontAwesomeIcon, themeLevel2, themeLevel3, useEscape, useUserInitials } from 'common-components';
import _ from 'lodash';
import React, { FormEventHandler, forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { ReadyState } from 'react-use-websocket';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import { ulid } from 'ulid';
import { useChatbot } from './context';
import { ChatMessage, MessageType } from './types';

const remarkPlugins = [remarkGfm, remarkBreaks];
const CHAT_COPY_COOLDOWN_IN_MS = 2000;

const ChatContainer = styled(Paper)(({ theme }) => ({
  borderRadius: theme.typography.pxToRem(theme.shape.borderRadius * 2),
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  width: '40vw',
  height: 'calc(100vh - 4.125rem)',
  [theme.breakpoints.down('lg')]: {
    width: '60vw',
  },
}));
const ChatbotImage = styled('img')(({ theme }) => ({
  width: theme.typography.pxToRem(99),
  display: 'block',
}));
const StyledMessageBody = styled(Box)(({ theme }) => ({
  minWidth: 0,
  overflowX: 'auto',
  overflowY: 'hidden',
  borderRadius: theme.typography.pxToRem(theme.shape.borderRadius * 2),
  backgroundColor: theme.palette.success.main,
}));
const StyledResponseAction = styled(IconButton)(({ theme }) => ({
  width: theme.typography.pxToRem(40),
  height: theme.typography.pxToRem(40),
  borderRadius: '50%',
  [`&.${iconButtonClasses.disabled}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.text.primary,
  },
}));
const SendButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  width: theme.typography.pxToRem(60),
  height: theme.typography.pxToRem(60),
  borderRadius: theme.typography.pxToRem(theme.shape.borderRadius * 2),
}));

interface MessageProps extends BoxProps {
  message: ChatMessage;
}
interface ResponseActionProps extends IconButtonProps {
  iconName: IconName;
}

export const ChatbotIcon = (props: BoxProps<'img'>) => {
  return <Box component={ChatbotImage} src="/assets/images/coolchatbotIcon.png" alt="Hermes" {...props} />;
};

const ChatHeader = () => {
  const { t } = useTranslation('chatbot');
  return (
    <ThemeProvider theme={themeLevel3}>
      <Stack bgcolor="background.default" direction="row" p={4} spacing={3} alignItems="center">
        <ChatbotIcon width="2rem" />
        <Typography variant="body3">{t('name')}</Typography>
      </Stack>
    </ThemeProvider>
  );
};

const MessageBody = ({ message, ...props }: MessageProps) => {
  const { t } = useTranslation('chatbot');
  return (
    <StyledMessageBody px={4} {...props}>
      {message.isError ? (
        <Stack my={4} direction="row" alignItems="center" justifyContent="center" color="error.main" spacing={1}>
          <FontAwesomeIcon fixedWidth icon={['fal', 'circle-exclamation']} />
          <Typography variant="body2">{t('errorOccurred')}</Typography>
        </Stack>
      ) : (
        <Typography variant="body2" component={Markdown} remarkPlugins={remarkPlugins}>
          {message.body}
        </Typography>
      )}
    </StyledMessageBody>
  );
};

const UserMessage = (props: MessageProps) => {
  const stringAvatar = useUserInitials();
  return (
    <Stack direction="row-reverse" spacing={4}>
      <Box alignSelf="flex-end">
        <Avatar sx={{ bgcolor: 'success.main' }}>
          <Typography variant="body2" color="text.primary">
            {stringAvatar}
          </Typography>
        </Avatar>
      </Box>
      <MessageBody
        {...props}
        sx={{
          borderBottomRightRadius: 0,
        }}
      />
    </Stack>
  );
};

const ResponseAction = ({ iconName, ...props }: ResponseActionProps) => {
  return (
    <StyledResponseAction {...props}>
      <FontAwesomeIcon fixedWidth size="sm" icon={['fal', iconName]} />
    </StyledResponseAction>
  );
};

const BotMessage = (props: MessageProps) => {
  const { onFeedback } = useChatbot();
  const [isCopied, setIsCopied] = useState(false);
  const [isThumbsDownClicked, setIsThumbsDownClicked] = useState(false);

  const onCopy = () => {
    navigator.clipboard.writeText(props.message.body);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), CHAT_COPY_COOLDOWN_IN_MS);
  };

  const onThumbsDown = () => {
    onFeedback(props.message.id);
    setIsThumbsDownClicked(true);
  };

  return (
    <Stack direction="row" spacing={4} pr={20}>
      <Box alignSelf="flex-end">
        <ChatbotIcon width="4.125rem" />
      </Box>
      <MessageBody
        {...props}
        sx={{
          bgcolor: `${themeLevel2.palette.background.default}de`,
          borderBottomLeftRadius: 0,
        }}
      />
      {props.message.type === MessageType.Assistant && !props.message.isError && (
        <Stack direction="row" pt={2} spacing={1}>
          <ResponseAction onClick={onCopy} disabled={isCopied} iconName={isCopied ? 'clipboard-check' : 'copy'} />
          <ResponseAction
            onClick={onThumbsDown}
            disabled={isThumbsDownClicked}
            iconName={isThumbsDownClicked ? 'check' : 'thumbs-down'}
          />
        </Stack>
      )}
    </Stack>
  );
};

const ChatContent = () => {
  const { t } = useTranslation('chatbot');
  const { messages, isWaitingResponse } = useChatbot();
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollBehavior = useRef<ScrollBehavior>();

  useEffect(() => {
    scrollRef.current?.scrollTo({ top: scrollRef.current.scrollHeight, behavior: scrollBehavior.current });
    scrollBehavior.current = 'smooth';
  }, [messages]);

  return (
    <Stack flex={1} overflow="auto" p={4} pb={0} spacing={4} ref={scrollRef}>
      <BotMessage message={{ id: ulid(), type: MessageType.AssistantIntro, body: t('introMessage') }} />
      {messages.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === MessageType.User ? <UserMessage message={item} /> : <BotMessage message={item} />}
        </React.Fragment>
      ))}
      {isWaitingResponse && (
        <BotMessage
          message={{ id: ulid(), type: MessageType.AssistantResponding, body: t('assistantResponsePlaceholder') }}
        />
      )}
    </Stack>
  );
};

const ChatFooter = () => {
  const { t } = useTranslation('chatbot');
  const { open, onSendMessage, readyState, isWaitingResponse } = useChatbot();
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const isDisabled = readyState !== ReadyState.OPEN || isWaitingResponse || _.isEmpty(inputValue.trim());

  const sendHandler: FormEventHandler = (event) => {
    event.preventDefault();
    onSendMessage(inputValue.trim());
    setInputValue('');
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [open]);

  return (
    <form onSubmit={sendHandler}>
      <Stack p={4} pt={0}>
        <Typography px={12} py={4} variant="body4" fontStyle="italic" textAlign="center">
          {t('disclaimer')}
        </Typography>
        <TextField
          autoFocus
          autoComplete="off"
          inputRef={inputRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={`${t('chatPlaceholder')}`}
          inputProps={{
            style: {
              height: '4rem',
            },
          }}
          InputProps={{
            sx: { borderRadius: 2 },
            endAdornment: (
              <SendButton type="submit" variant="contained" disabled={isDisabled}>
                {t('action.send')}
              </SendButton>
            ),
          }}
        />
      </Stack>
    </form>
  );
};

export const ChatWindow = forwardRef<HTMLDivElement>((props, ref) => {
  const { open, anchorEl, onClose } = useChatbot();

  useEscape(onClose);

  return (
    <Backdrop open={open}>
      <Popper
        ref={ref}
        open={open}
        anchorEl={anchorEl}
        placement="left-end"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 16],
            },
          },
        ]}
        disablePortal
        keepMounted
      >
        <ChatContainer>
          <ChatHeader />
          <ChatContent />
          <ChatFooter />
        </ChatContainer>
      </Popper>
    </Backdrop>
  );
});
