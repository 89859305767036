var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyAccessValue } from '../enum';
import { useForm } from '../hooks';
import { ConnectionConfigDialog } from './connectionConfigDialog';
import { validateXAdsConnectionSchema } from './constants';
import { ConnectionStatus } from './selectors';
export var XAdsConnectorDialog = function (_a) {
    var open = _a.open, connectionData = _a.connectionData, connectionBusinessType = _a.connectionBusinessType, props = __rest(_a, ["open", "connectionData", "connectionBusinessType"]);
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var _b = useForm(useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        var connectionConfig = connectionData === null || connectionData === void 0 ? void 0 : connectionData.details;
        return {
            initialValues: {
                consumerKey: (_a = connectionConfig === null || connectionConfig === void 0 ? void 0 : connectionConfig.consumerKey) !== null && _a !== void 0 ? _a : '',
                consumerSecret: (_b = connectionConfig === null || connectionConfig === void 0 ? void 0 : connectionConfig.consumerSecret) !== null && _b !== void 0 ? _b : '',
                accessToken: (_c = connectionConfig === null || connectionConfig === void 0 ? void 0 : connectionConfig.accessToken) !== null && _c !== void 0 ? _c : '',
                accessTokenSecret: (_d = connectionConfig === null || connectionConfig === void 0 ? void 0 : connectionConfig.accessTokenSecret) !== null && _d !== void 0 ? _d : '',
                adsAccountId: (_e = connectionConfig === null || connectionConfig === void 0 ? void 0 : connectionConfig.adsAccountId) !== null && _e !== void 0 ? _e : '',
                access: (_f = connectionData === null || connectionData === void 0 ? void 0 : connectionData.access) !== null && _f !== void 0 ? _f : KeyAccessValue.Write,
                status: (_g = connectionData === null || connectionData === void 0 ? void 0 : connectionData.status) !== null && _g !== void 0 ? _g : ConnectionStatus.Enabled,
            },
            validationSchema: validateXAdsConnectionSchema(t),
        };
    }, [t, connectionData])), values = _b.values, isDirty = _b.isDirty, handleChange = _b.handleChange, handleBlur = _b.handleBlur, handleSubmit = _b.handleSubmit, handleReset = _b.handleReset, getError = _b.getError, errors = _b.errors;
    var onCancel = function () {
        var _a;
        handleReset();
        (_a = props.onCancel) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    var onSubmit = handleSubmit(function (castedValues) {
        var _a;
        props.onSubmit(__assign(__assign({}, connectionData), { connectionTypeId: props.connectionTypeId, orgId: (_a = props.orgId) !== null && _a !== void 0 ? _a : '', access: castedValues.access, status: castedValues.status, details: {
                consumerKey: castedValues.consumerKey,
                consumerSecret: castedValues.consumerSecret,
                accessToken: castedValues.accessToken,
                accessTokenSecret: castedValues.accessTokenSecret,
                adsAccountId: castedValues.adsAccountId,
            } }));
    });
    return (_jsx(ConnectionConfigDialog, { open: open, requesting: props.requesting, connectionBusinessType: connectionBusinessType, externalError: props.externalError, inputs: [
            { key: 'adsAccountId', label: t('xAdsConnectorDialog.adsAccountId') },
            { key: 'consumerKey', label: t('xAdsConnectorDialog.consumerKey'), type: 'password' },
            { key: 'consumerSecret', label: t('xAdsConnectorDialog.consumerSecret'), type: 'password' },
            { key: 'accessToken', label: t('xAdsConnectorDialog.accessToken'), type: 'password' },
            { key: 'accessTokenSecret', label: t('xAdsConnectorDialog.accessTokenSecret'), type: 'password' },
        ], formStates: { values: values, isDirty: isDirty, errors: errors, handleChange: handleChange, handleBlur: handleBlur, getError: getError }, provider: props.provider, onSubmit: onSubmit, onCancel: onCancel, connectionData: connectionData, connectionTypeId: props.connectionTypeId, orgId: props.orgId }));
};
