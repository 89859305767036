export var IdRepresentation;
(function (IdRepresentation) {
    IdRepresentation[IdRepresentation["ADD_MODE"] = -1] = "ADD_MODE";
    IdRepresentation[IdRepresentation["EMPTY"] = 0] = "EMPTY";
})(IdRepresentation || (IdRepresentation = {}));
export var ConnectionSource;
(function (ConnectionSource) {
    ConnectionSource["Trovo"] = "trovo";
    ConnectionSource["Klaviyo"] = "klaviyo";
    ConnectionSource["MailChimp"] = "mailchimp";
    ConnectionSource["LegacyTrovo"] = "legacyTrovo";
    ConnectionSource["SendGrid"] = "sendgrid";
    ConnectionSource["Meta"] = "meta";
    ConnectionSource["GoogleAds"] = "googleAds";
})(ConnectionSource || (ConnectionSource = {}));
export var FeatureFlagStatus;
(function (FeatureFlagStatus) {
    FeatureFlagStatus[FeatureFlagStatus["Limited"] = 0] = "Limited";
    FeatureFlagStatus[FeatureFlagStatus["Released"] = 1] = "Released";
})(FeatureFlagStatus || (FeatureFlagStatus = {}));
export var AccessFeatureFlag;
(function (AccessFeatureFlag) {
    AccessFeatureFlag["MailChimpConnector"] = "MAILCHIMP_ACCESS";
})(AccessFeatureFlag || (AccessFeatureFlag = {}));
export var KeyAccessName;
(function (KeyAccessName) {
    KeyAccessName["ReadOnly"] = "Read Only";
    KeyAccessName["Write"] = "Write Audience Data";
})(KeyAccessName || (KeyAccessName = {}));
export var KeyAccessValue;
(function (KeyAccessValue) {
    KeyAccessValue[KeyAccessValue["ReadOnly"] = 0] = "ReadOnly";
    KeyAccessValue[KeyAccessValue["Write"] = 1] = "Write";
})(KeyAccessValue || (KeyAccessValue = {}));
