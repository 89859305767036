var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, ClickAwayListener, Paper, Popper, ThemeProvider, styled, useTheme, } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import { useActiveFullscreenSession } from '../../fullscreen';
import { useEscape, usePrintParam, useRectSize, useTick, useTimeOut } from '../../hooks';
import { FallbackComponent } from '../fallback';
import { themeMenuPopover } from '../menupopover';
import { MouseEventType, createVirtualElement, getEventData, getMaxLength, transformPlotData } from './constants';
var StyledPlot = styled(Plot)(function (_a) {
    var theme = _a.theme, showTooltipEvent = _a.showTooltipEvent, onClick = _a.onClick;
    return ({
        width: '100%',
        height: '100%',
        text: {},
        '.annotation-text': __assign(__assign({}, theme.typography.h1), { fontSize: "".concat(theme.typography.h1.fontSize, " !important") }),
        'g.points g.point': {
            filter: "drop-shadow(".concat(theme.shadows[5], ")"),
        },
        '.legendpoints': {
            '& > path': {
                d: "path('M 6 0 A 6 6 0 1 1 0 -6 A 6 6 0 0 1 6 0 Z')",
            },
        },
        '> div > div > svg:nth-of-type(1) > g': {
            '&, .nsewdrag ': {
                cursor: "".concat(showTooltipEvent === MouseEventType.Click && onClick ? 'pointer' : 'default'),
            },
        },
        '.sunburstlayer .slice:not(:first-child) .slicetext': {
            fontWeight: 600,
        },
    });
});
var PlotMemo = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var _k = useState("plot-rendering"), renderStatus = _k[0], setRenderStatus = _k[1];
    var printPDF = usePrintParam()[0];
    var activeFullscreen = useActiveFullscreenSession()[0];
    var _l = props.fullscreenName, fullscreenName = _l === void 0 ? activeFullscreen : _l;
    var theme = useTheme();
    var shouldUpSize = activeFullscreen && fullscreenName === activeFullscreen;
    var data = useMemo(function () { return props.data.map(function (d) { return transformPlotData(d, props.containerRect); }); }, [props.containerRect, props.data]);
    var font = useMemo(function () {
        var _a;
        return (__assign({ family: theme.typography.fontFamily, size: shouldUpSize ? 16 : 10, color: theme.palette.text.primary }, (_a = props.layout) === null || _a === void 0 ? void 0 : _a.font));
    }, [theme.typography.fontFamily, theme.palette.text.primary, shouldUpSize, (_a = props.layout) === null || _a === void 0 ? void 0 : _a.font]);
    var _m = useTick(props.xAxisAutoTick, (_b = props.containerRect) === null || _b === void 0 ? void 0 : _b.width, "".concat(font.size, "px ").concat(font.family)), dTick = _m[0], tick0 = _m[1];
    var originalMargin = __assign({ l: 0, r: 0, b: 0, t: 0, pad: 0 }, (_c = props.layout) === null || _c === void 0 ? void 0 : _c.margin);
    var fullscreenMargin = {
        t: originalMargin.t * 1.5,
        b: originalMargin.b * 1.5,
        l: originalMargin.l * 1.5,
        r: originalMargin.r * 1.5,
        pad: originalMargin.pad * 1.5,
    };
    var customizeMargin = shouldUpSize ? fullscreenMargin : originalMargin;
    return (_jsxs(_Fragment, { children: [_jsx(StyledPlot, { showTooltipEvent: props.showTooltipEvent, className: props.className, useResizeHandler: true, onHover: props.onHover, onClick: props.onClick, onUnhover: props.onUnHover, onAfterPlot: function () {
                    setRenderStatus("plot-rendered");
                }, data: data, config: __assign(__assign({}, props.config), { displaylogo: false, setBackground: 'transparent', modeBarButtons: [[]], responsive: true, staticPlot: !!printPDF, showTips: false }), layout: __assign(__assign({ autosize: true, plot_bgcolor: 'transparent', paper_bgcolor: 'transparent' }, props.layout), { font: font, margin: customizeMargin, xaxis: __assign(__assign({ automargin: true, fixedrange: true, tickcolor: 'transparent', rangemode: 'nonnegative', gridcolor: theme.palette.divider, ticklen: 10, tick0: tick0, dtick: dTick, ticks: 'outside' }, (((_e = (_d = props.layout) === null || _d === void 0 ? void 0 : _d.xaxis) === null || _e === void 0 ? void 0 : _e.showgrid)
                        ? { linecolor: theme.palette.divider, zerolinecolor: theme.palette.divider }
                        : { showgrid: false })), (_f = props.layout) === null || _f === void 0 ? void 0 : _f.xaxis), yaxis: __assign(__assign({ automargin: true, fixedrange: true, tickcolor: 'transparent', rangemode: 'nonnegative', gridcolor: theme.palette.divider, ticklen: 10, ticks: 'outside' }, (((_h = (_g = props.layout) === null || _g === void 0 ? void 0 : _g.yaxis) === null || _h === void 0 ? void 0 : _h.showgrid)
                        ? { linecolor: theme.palette.divider, zerolinecolor: theme.palette.divider }
                        : { showgrid: false })), (_j = props.layout) === null || _j === void 0 ? void 0 : _j.yaxis) }) }), _jsx(Box, { display: "none", className: renderStatus })] }));
}, function (prevProps, nextProp) {
    var isSameData = JSON.stringify(prevProps.data) === JSON.stringify(nextProp.data);
    var isSameConfig = JSON.stringify(prevProps.config) === JSON.stringify(nextProp.config);
    var isSameLayout = JSON.stringify(prevProps.layout) === JSON.stringify(nextProp.layout);
    var isSameContainerRect = JSON.stringify(prevProps.containerRect) === JSON.stringify(nextProp.containerRect);
    return isSameData && isSameConfig && isSameLayout && isSameContainerRect;
});
export var PlotComponent = function (_a) {
    var _b, _c;
    var _d = _a.showTooltipEvent, showTooltipMode = _d === void 0 ? MouseEventType.Hover : _d, TooltipRender = _a.TooltipRender, onResize = _a.onResize, props = __rest(_a, ["showTooltipEvent", "TooltipRender", "onResize"]);
    var _e = useTimeOut(), setTimeout = _e[1], resetTimeOut = _e[2];
    var _f = useState(false), showTooltip = _f[0], setShowTooltip = _f[1];
    var _g = React.useState(null), anchorEl = _g[0], setAnchorEl = _g[1];
    var _h = useState(), tooltipData = _h[0], setTooltipData = _h[1];
    var _j = useRectSize(onResize), containerRef = _j[0], containerRect = _j[1];
    var ignoreNextClickAwayRef = useRef(false);
    useEscape(useCallback(function () { return setShowTooltip(false); }, []));
    var onHover = function (event) {
        var _a = getEventData(event), label = _a.label, value = _a.value, clientX = _a.clientX, clientY = _a.clientY;
        if (label || value) {
            setTimeout(function () {
                setTooltipData({ label: label, value: value, eventType: MouseEventType.Hover });
                setAnchorEl(createVirtualElement(clientX, clientY));
                setShowTooltip(true);
            }, 500);
        }
    };
    var onUnHover = function () {
        setShowTooltip(false);
        resetTimeOut();
    };
    var onClick = function (event) {
        resetTimeOut();
        var _a = getEventData(event), label = _a.label, value = _a.value, clientX = _a.clientX, clientY = _a.clientY;
        if (label || value) {
            setTooltipData({ label: label, value: value, eventType: MouseEventType.Click, dateRange: value.dateRange });
            setAnchorEl(createVirtualElement(clientX, clientY));
            setShowTooltip(true);
            ignoreNextClickAwayRef.current = true;
        }
    };
    var onClickAwayTooltip = function () {
        if (!ignoreNextClickAwayRef.current) {
            setShowTooltip(false);
        }
        ignoreNextClickAwayRef.current = false;
    };
    return (_jsx(Box, __assign({ ref: containerRef, component: "div", height: "100%", width: "100%", overflow: "hidden" }, { children: _jsxs(FallbackComponent, __assign({ requesting: props.requesting, isNoData: props.isNoData }, { children: [_jsx(PlotMemo, __assign({ containerRect: containerRect, onHover: showTooltipMode === MouseEventType.Hover ? onHover : undefined, onUnHover: showTooltipMode === MouseEventType.Hover ? onUnHover : undefined, onClick: onClick, showTooltipEvent: showTooltipMode }, props)), _jsx(ThemeProvider, __assign({ theme: themeMenuPopover }, { children: _jsx(ClickAwayListener, __assign({ onClickAway: onClickAwayTooltip, mouseEvent: "onPointerUp" }, { children: _jsx(Popper, __assign({ open: showTooltip, anchorEl: anchorEl, placement: "right", onMouseOver: function () {
                                showTooltipMode === MouseEventType.Hover && setShowTooltip(true);
                            }, onMouseLeave: function () {
                                ignoreNextClickAwayRef.current = false;
                                showTooltipMode === MouseEventType.Hover && setShowTooltip(false);
                                resetTimeOut();
                            }, sx: __assign({ zIndex: 'tooltip' }, (!props.allowTooltipEvents && { userSelect: 'none', pointerEvents: 'none' })) }, { children: _jsx(Paper, __assign({ dangerouslySetInnerHTML: !TooltipRender
                                    ? { __html: (_c = (_b = props.templatetooltip) === null || _b === void 0 ? void 0 : _b.call(props, tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.label, tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.value)) !== null && _c !== void 0 ? _c : '' }
                                    : undefined, sx: { padding: 2.5 } }, props.tooltipProps, { children: TooltipRender && _jsx(TooltipRender, __assign({}, tooltipData)) })) })) })) }))] })) })));
};
export { MouseEventType, getMaxLength };
