import { GA_EVENTS, UseAPI, useAPI, useCachedData, useExportExcelAPI } from 'common-components';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Operators, QuickSelectGroup } from '../enums';
import { Audience, AudienceFieldsResponse, CellFormData, FieldConfig, FilterFormValues } from '../types';
import { useAudience } from './context';

const FIELD_API = '/api/audience/field';
const AUDIENCE_API = '/api/audience/legacy';
const AUDIENCE_DETAIL_API = `${AUDIENCE_API}/{id}`;
const PROSPECT_COUNT_API = '/api/audience/prospect/count';
const PROSPECT_EXPORT_API = '/api/audience/prospect/export';

const DATE_TIME_FORMAT = 'YYYYMMDD HHmmss';

export const useAudienceAPI = (id?: number): UseAPI<Audience> => {
  const { audienceId = id } = useAudience();
  const [data, fetchData, clearData] = useAPI<Audience>(AUDIENCE_DETAIL_API, { id: audienceId });

  const fetchDataWrapper = useCallback(() => {
    if (audienceId) {
      fetchData();
    }
  }, [fetchData, audienceId]);

  return [data, fetchDataWrapper, clearData];
};

export const useFieldAPI = () => {
  return useCachedData<AudienceFieldsResponse>(FIELD_API);
};

export const useAudienceExportAPI = (fileName = 'New Export'): UseAPI => {
  const gaEvents = {
    category: GA_EVENTS.audience.__name,
    action: GA_EVENTS.audience.downloadAudience,
  };
  const now = moment().format(DATE_TIME_FORMAT);
  return useExportExcelAPI(PROSPECT_EXPORT_API, {}, `${fileName} ${now}.csv`, gaEvents, 'POST');
};

export const useProspectCountAPI = () => {
  return useAPI<{ count: number }>(PROSPECT_COUNT_API, undefined, 'POST');
};

export const useCreateAudienceAPI = () => {
  return useAPI<Audience>(AUDIENCE_API, undefined, 'POST');
};
export const useUpdateAudienceAPI = (id?: number) => {
  return useAPI<Audience>(AUDIENCE_DETAIL_API, { id }, 'PUT');
};
export const useSaveAudienceAPI = () => {
  const { audienceId } = useAudience();
  const create = useCreateAudienceAPI();
  const update = useUpdateAudienceAPI(audienceId);
  return audienceId ? update : create;
};

export const useExportFields = (fieldsFilter: FieldConfig[]) => {
  const getFieldsByQuickSelect = useCallback(
    (quickSelect: QuickSelectGroup) =>
      fieldsFilter.filter((item) => item.quickSelectGroup?.includes(quickSelect)).map((field) => field.id),
    [fieldsFilter]
  );

  const defaultNewExportFields = useMemo(
    () => getFieldsByQuickSelect(QuickSelectGroup.Emails),
    [getFieldsByQuickSelect]
  );

  const [exportFields, setExportFields] = useState(defaultNewExportFields);
  const resetForNewExport = useCallback(() => setExportFields(defaultNewExportFields), [defaultNewExportFields]);

  useEffect(() => {
    setExportFields(defaultNewExportFields);
  }, [defaultNewExportFields]);

  return {
    exportFields,
    setExportFields,
    resetForNewExport,
  };
};

export const useAutoEnableExportVisitMetrics = (
  visitFields: CellFormData[],
  handleReset: (field: keyof FilterFormValues) => void,
  setFieldValue: (value: any, field: keyof FilterFormValues) => void,
  audience?: Audience
) => {
  const autoEnableRef = useRef(true);

  const onReset = useCallback(() => {
    handleReset('exportVisitMetrics');
    autoEnableRef.current = false;
  }, [handleReset]);

  useEffect(() => {
    autoEnableRef.current = !audience;
  }, [audience]);

  const hasFilter = useMemo(
    () => Object.values(visitFields).some((filter) => filter.operator !== Operators.DoNotFilter),
    [visitFields]
  );

  useEffect(() => {
    if (autoEnableRef.current && hasFilter) {
      setFieldValue(true, 'exportVisitMetrics');
    }
  }, [hasFilter, setFieldValue]);

  useEffect(() => {
    autoEnableRef.current = true;
  }, [visitFields]);

  return { onReset };
};
