import { SessionActive, UsersManagement } from 'common-components';
import { GroupAnalysis } from '../campaign360/groupanalysis';
import { IdealLocationDetail } from '../campaign360/ideallocationdetail';
import { Insights } from '../campaign360/insights';
import { ResponseProfileExplorer } from '../campaign360/responseprofileexplorer';
import { StatSummary } from '../campaign360/statsummary';
import { Demographic } from '../trafficinsightdialog/demographic';
import { Geography } from '../trafficinsightdialog/geography';
import { AnalyzerType, ContentType } from '../trafficinsightdialog/types';
import { VisitorBreakdownEnum } from '../webresolution/constants';
import { Classification } from '../webresolution/visitorclassification/classification';

export const FULL_SCREEN_COMPONENTS: Record<string, React.FC> = {
  [SessionActive.EmailOnly]: () => <Classification type={VisitorBreakdownEnum.EmailOnly} />,
  [SessionActive.EnrichedB2C]: () => <Classification type={VisitorBreakdownEnum.EnrichedB2C} />,
  [SessionActive.EnrichedB2B]: () => <Classification type={VisitorBreakdownEnum.EnrichedB2B} />,
  [SessionActive.StatSummary]: StatSummary,
  [SessionActive.LocationDetail]: () => <IdealLocationDetail zoom={3.6} />,
  [SessionActive.GroupAnalysis]: GroupAnalysis,
  [SessionActive.Insights]: Insights,
  [SessionActive.Age]: () => <Demographic analyzerType={AnalyzerType.Age} contentType={ContentType.B2C} />,
  [SessionActive.Gender]: () => <Demographic analyzerType={AnalyzerType.Gender} contentType={ContentType.B2C} />,
  [SessionActive.Income]: () => <Demographic analyzerType={AnalyzerType.Income} contentType={ContentType.B2C} />,
  [SessionActive.Married]: () => <Demographic analyzerType={AnalyzerType.Married} contentType={ContentType.B2C} />,
  [SessionActive.Homeowner]: () => <Demographic analyzerType={AnalyzerType.Homeowner} contentType={ContentType.B2C} />,
  [SessionActive.NetWorth]: () => <Demographic analyzerType={AnalyzerType.NetWorth} contentType={ContentType.B2C} />,
  [SessionActive.Department]: () => (
    <Demographic analyzerType={AnalyzerType.Department} contentType={ContentType.B2B} />
  ),
  [SessionActive.SeniorityLevel]: () => (
    <Demographic analyzerType={AnalyzerType.SeniorityLevel} contentType={ContentType.B2B} />
  ),
  [SessionActive.CompanyRevenue]: () => (
    <Demographic analyzerType={AnalyzerType.CompanyRevenue} contentType={ContentType.B2B} />
  ),
  [SessionActive.CompanyEmployeeCount]: () => (
    <Demographic analyzerType={AnalyzerType.CompanyEmployeeCount} contentType={ContentType.B2B} />
  ),
  [SessionActive.Geography]: () => (
    <Geography analyzerType={AnalyzerType.Geography} contentType={ContentType.B2C} zoom={4} />
  ),
  [SessionActive.ContactGeography]: () => (
    <Geography analyzerType={AnalyzerType.ContactGeography} contentType={ContentType.B2B} zoom={4} />
  ),
  [SessionActive.CompanyGeography]: () => (
    <Geography analyzerType={AnalyzerType.CompanyGeography} contentType={ContentType.B2B} zoom={4} />
  ),
  [SessionActive.AccountManagement]: UsersManagement,
  [SessionActive.ResponseProfileExplorer]: () => <ResponseProfileExplorer fullScreen />,
};
