var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyAccessValue } from '../enum';
import { useForm } from '../hooks';
import { ConnectionConfigDialog } from './connectionConfigDialog';
import { validateGoogleAdsConnectionSchema } from './constants';
import { ConnectionStatus, useOAuth2Google } from './selectors';
export var GoogleAdsConnectorDialog = function (_a) {
    var _b;
    var open = _a.open, connectionData = _a.connectionData, connectionBusinessType = _a.connectionBusinessType, props = __rest(_a, ["open", "connectionData", "connectionBusinessType"]);
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var _c = useOAuth2Google(props.orgId), oAuth2Data = _c[0], fetchOAuth2Google = _c[1], clearOAuth2Google = _c[2];
    var connectionConfig = connectionData === null || connectionData === void 0 ? void 0 : connectionData.details;
    var isTestable = (_b = connectionConfig === null || connectionConfig === void 0 ? void 0 : connectionConfig.isTestable) !== null && _b !== void 0 ? _b : false;
    var _d = useForm(useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        return ({
            initialValues: {
                adsAccountId: (_a = connectionConfig === null || connectionConfig === void 0 ? void 0 : connectionConfig.adsAccountId) !== null && _a !== void 0 ? _a : '',
                clientId: (_b = connectionConfig === null || connectionConfig === void 0 ? void 0 : connectionConfig.clientId) !== null && _b !== void 0 ? _b : '',
                clientSecret: (_c = connectionConfig === null || connectionConfig === void 0 ? void 0 : connectionConfig.clientSecret) !== null && _c !== void 0 ? _c : '',
                developerToken: (_d = connectionConfig === null || connectionConfig === void 0 ? void 0 : connectionConfig.developerToken) !== null && _d !== void 0 ? _d : '',
                refreshToken: (_e = connectionConfig === null || connectionConfig === void 0 ? void 0 : connectionConfig.refreshToken) !== null && _e !== void 0 ? _e : '',
                access: (_f = connectionData === null || connectionData === void 0 ? void 0 : connectionData.access) !== null && _f !== void 0 ? _f : KeyAccessValue.Write,
                status: (_g = connectionData === null || connectionData === void 0 ? void 0 : connectionData.status) !== null && _g !== void 0 ? _g : ConnectionStatus.Disabled,
            },
            validationSchema: validateGoogleAdsConnectionSchema(t),
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t])), values = _d.values, errors = _d.errors, isDirty = _d.isDirty, handleChange = _d.handleChange, handleBlur = _d.handleBlur, handleSubmit = _d.handleSubmit, handleReset = _d.handleReset, getError = _d.getError;
    useEffect(function () {
        return function () {
            clearOAuth2Google();
        };
    }, [clearOAuth2Google]);
    useEffect(function () {
        var _a, _b;
        if ((_a = oAuth2Data.data) === null || _a === void 0 ? void 0 : _a.url) {
            window.open("".concat((_b = oAuth2Data.data) === null || _b === void 0 ? void 0 : _b.url), '_self');
        }
    }, [oAuth2Data]);
    var onCancel = function () {
        var _a;
        handleReset();
        clearOAuth2Google();
        (_a = props.onCancel) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    var onSubmit = handleSubmit(function (castedValues) {
        var _a;
        props.onSubmit(__assign(__assign({}, connectionData), { connectionTypeId: props.connectionTypeId, orgId: (_a = props.orgId) !== null && _a !== void 0 ? _a : '', details: {
                adsAccountId: castedValues.adsAccountId,
                developerToken: castedValues.developerToken,
                clientId: castedValues.clientId,
                clientSecret: castedValues.clientSecret,
                refreshToken: castedValues.refreshToken,
            }, access: castedValues.access, status: castedValues.status }));
    });
    var onAuthorize = handleSubmit(function (castedValues) {
        var _a;
        fetchOAuth2Google({
            connection: __assign(__assign({}, connectionData), { orgId: (_a = props.orgId) !== null && _a !== void 0 ? _a : '', connectionTypeId: props.connectionTypeId, details: {
                    adsAccountId: castedValues.adsAccountId,
                    developerToken: castedValues.developerToken,
                    clientId: castedValues.clientId,
                    clientSecret: castedValues.clientSecret,
                }, access: castedValues.access, status: castedValues.status }),
        });
    });
    var inputs = [
        { key: 'adsAccountId', label: t('googleAdsConnectorDialog.adsAccountId'), type: 'password' },
        { key: 'clientId', label: t('googleAdsConnectorDialog.clientId'), type: 'password' },
        { key: 'clientSecret', label: t('googleAdsConnectorDialog.clientSecret'), type: 'password' },
        { key: 'developerToken', label: t('googleAdsConnectorDialog.developerToken'), type: 'password' },
    ];
    return (_jsx(ConnectionConfigDialog, { open: open, connectionBusinessType: connectionBusinessType, requesting: props.requesting, inputs: inputs, formStates: { values: values, isDirty: isDirty, errors: errors, handleChange: handleChange, handleBlur: handleBlur, getError: getError }, provider: props.provider, onSubmit: onSubmit, onCancel: onCancel, externalError: props.externalError, onAuthorize: onAuthorize, isRequiredAuthorization: !isTestable, connectionData: connectionData, connectionTypeId: props.connectionTypeId, orgId: props.orgId, isTestable: isTestable }));
};
