import { UseAPI, useStateParam, useUserSessionAPI } from 'common-components';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { DateSelectorDependency, useDateSelectorBindingAPI } from '../../../selectors';
import { useDateRangeState } from '../../../state/app';
import { DateRangeStateEnum } from '../../../state/app/appSlice';
import { DATE_FORMAT_EXCEL, getMetricsArray } from '../constants';
import { useCampaignState, useLookupCampaignsAPI, useMetricTypeState } from '../selectors';
import { DefaultFilter, EXCEL_COLUMN_RPE, ResponseProfileExporers as ResponseProfileExplorers } from '../types';
import { RPEExcellFileName } from './constant';
import { RPEViewMode } from './types';

export const useViewModeState = (): [string | undefined, (state: string) => void] => {
  return useStateParam<string>(
    useMemo(
      () => ({
        initialState: RPEViewMode.Descriptive,
        paramName: 'viewMode',
        validator: (states) => Object.values(RPEViewMode).includes(states as RPEViewMode),
      }),
      []
    )
  );
};

export const useResponseProfileExplorerAPI = (): UseAPI<ResponseProfileExplorers> => {
  const [campaigns] = useLookupCampaignsAPI();
  const [campaignIds] = useCampaignState();
  const [metricType] = useMetricTypeState();
  const [userSessionData] = useUserSessionAPI();
  const [responseProfiles, fetchRPEData, ...rest] = useDateSelectorBindingAPI<ResponseProfileExplorers>({
    depend: DateSelectorDependency.AnalyticsRPE_B2C,
    params: {
      organizationId: userSessionData.data?.orgId,
    },
    convertDateToUTC: true,
    method: 'POST',
    dateRangeState: DateRangeStateEnum.Campaign360,
  });

  const doFetchData = useCallback(() => {
    const events = getMetricsArray(metricType);
    if (events && campaignIds?.length && campaigns.data?.length) {
      return fetchRPEData(
        {
          events,
          campaigns: _.includes(campaignIds, DefaultFilter.All) ? _.map(campaigns.data, 'id') : campaignIds,
        },
        true
      );
    }
  }, [campaignIds, campaigns.data, fetchRPEData, metricType]);

  return [responseProfiles, doFetchData, ...rest];
};

export const useVEPExcelData = () => {
  const [dateRange] = useDateRangeState();
  const [fromDate, toDate] = dateRange || [];

  const [data] = useResponseProfileExplorerAPI();
  const sheetData = data.data?.results.map((pr) => ({
    [EXCEL_COLUMN_RPE.StartDate]: fromDate?.startOf('day').format(DATE_FORMAT_EXCEL).toString(),
    [EXCEL_COLUMN_RPE.EndDate]: toDate?.endOf('day').format(DATE_FORMAT_EXCEL).toString(),
    [EXCEL_COLUMN_RPE.Campaign]: data.data?.campaigns.toString(),
    [EXCEL_COLUMN_RPE.Event]: data.data?.events.toString(),
    [EXCEL_COLUMN_RPE._Gender]: pr.profile.gender,
    [EXCEL_COLUMN_RPE.Age]: pr.profile.age,
    [EXCEL_COLUMN_RPE.Income]: pr.profile.income,
    [EXCEL_COLUMN_RPE.Region]: pr.profile.region,
    [EXCEL_COLUMN_RPE.Married]: pr.profile.married,
    [EXCEL_COLUMN_RPE.Homeowner]: pr.profile.homeowner,
    [EXCEL_COLUMN_RPE.Networth]: pr.profile.networth,
    [EXCEL_COLUMN_RPE.SegmentSize]: pr.profileStats.segmentSize,
    [EXCEL_COLUMN_RPE.ResponseRate]: pr.profileStats.responseRate,
    [EXCEL_COLUMN_RPE.LiftOverAverage]: pr.profileStats.liftOverAverage,
  }));
  const excelData = {
    name: RPEExcellFileName,
    sheets: [
      {
        name: RPEExcellFileName,
        header: sheetData ? _.keys(sheetData[0]) : [''],
        data: sheetData || [],
      },
    ],
  };
  return [excelData];
};

export const useActiveStepState = (): [number | undefined, (prevActiveStep: number) => void] => {
  const [responseProfiles] = useResponseProfileExplorerAPI();
  const maxSteps = useMemo(() => responseProfiles.data?.results.length ?? 0, [responseProfiles.data]);
  return useStateParam<number>(
    useMemo(
      () => ({
        initialState: 0,
        initialParam: true,
        paramName: 'activeStep',
        serialize: (state) => state.toString(),
        deserialize: (s) => _.parseInt(s),
        validator: (activeStep) => _.isNumber(activeStep) && activeStep < maxSteps,
      }),
      [maxSteps]
    )
  );
};
