import { ThemeProvider } from '@emotion/react';
import { Button, Grid, Link, ListItemIcon, MenuItem, Typography } from '@mui/material';
import {
  AUDIT_EVENTS,
  AlignTable,
  Card,
  CardContent,
  CardHeader,
  ConfirmDialog,
  ErrorMessageQueueBox,
  FallbackComponent,
  FontAwesomeIcon,
  GA_EVENTS,
  HeadCell,
  MarApiError,
  RowMenu,
  Table,
  themeLevel3,
  useAudit,
  useGA4,
  useSystemParamAPI,
} from 'common-components';
import moment from 'moment';
import { NestedMenuItem } from 'mui-nested-menu';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { AudiencesDialog } from './detail';
import { AudienceHistoryTab } from './history';
import { useAudienceExportAPI, useAudiencesAPI, useDeleteAudienceAPI } from './selectors';
import { AudienceListItem, AudienceSchedule } from './types';

interface AudienceActionMenuProps {
  row: AudienceListItem;
  selectedRow?: AudienceListItem;
  setExporting: (value: boolean) => void;
  setExportError: (value: MarApiError) => void;
  onDelete: (audience: AudienceListItem) => void;
  onEdit: (id: number) => void;
}

interface DownloadActionProps {
  row: AudienceListItem;
  schedule?: AudienceSchedule;
  nested?: boolean;
  label?: string;
  isParentOpen?: boolean;
  setExporting: (value: boolean) => void;
  setExportError: (value: MarApiError) => void;
}
const DownloadAction = ({
  isParentOpen,
  row,
  label,
  nested,
  schedule,
  setExporting,
  setExportError,
}: DownloadActionProps) => {
  const { t } = useTranslation(['audience', 'translation']);
  const [exportData, doFetchExport] = useAudienceExportAPI(
    row.id,
    schedule?.id,
    schedule?.id ? schedule.connectionName : row.name
  );

  useEffect(() => {
    setExporting(!!exportData.requesting);
  }, [exportData.requesting, setExporting]);

  useEffect(() => {
    setExportError(exportData.error);
  }, [exportData.error, setExportError]);

  if (!nested) {
    return (
      <MenuItem
        disabled={!!exportData.requesting}
        onClick={() => {
          doFetchExport();
        }}
      >
        <ListItemIcon>
          <FontAwesomeIcon size="lg" icon={['fal', 'file-export']} />
        </ListItemIcon>
        {label ?? t('menu.downloadAudience')}
      </MenuItem>
    );
  }

  return (
    <NestedMenuItem
      key={row.id}
      label={`${t('menu.downloadAudience')}`}
      rightIcon={<FontAwesomeIcon fontSize="1.375rem" icon={['fal', 'chevron-right']} />}
      leftIcon={<FontAwesomeIcon fontSize="1.375rem" icon={['fal', 'file-export']} />}
      parentMenuOpen={!!isParentOpen}
      MenuProps={{ keepMounted: true }}
    >
      {row.schedules.map((item) => (
        <DownloadAction
          key={item.connectionName}
          label={`${t('menu.downloadScheduleLabel', { connectionName: item.connectionName })}`}
          row={row}
          schedule={item}
          setExporting={setExporting}
          setExportError={setExportError}
        />
      ))}
    </NestedMenuItem>
  );
};

const NewAudienceButton = () => {
  const [systemParam] = useSystemParamAPI()
  const uri = `${process.env.REACT_APP_AUDIENCE_UI_URL ?? systemParam.data?.audienceUiUri}${window.location.search}`
  return (
    <Link sx={{ alignSelf: 'end', mb: 2 }} href={uri} target="_blank">
      <Button variant="contained">Try new Audience v2.0</Button>
    </Link>
  );
};

const AudienceActionMenu = ({
  row,
  selectedRow,
  onEdit,
  onDelete,
  setExporting,
  setExportError,
}: AudienceActionMenuProps) => {
  const { t } = useTranslation(['audience', 'translation']);
  const [open, setOpen] = useState(false);

  return (
    <RowMenu selected={selectedRow === row} keepMounted onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
      <MenuItem onClick={() => onEdit(row.id)}>
        <ListItemIcon>
          <FontAwesomeIcon size="lg" icon={['fal', 'pen']} />
        </ListItemIcon>
        {t('menu.editAudience')}
      </MenuItem>
      <DownloadAction
        row={row}
        isParentOpen={open}
        setExporting={setExporting}
        setExportError={setExportError}
        schedule={row.schedules[0]}
        nested={row.schedules.length > 1}
      />
      <MenuItem onClick={() => onDelete(row)}>
        <ListItemIcon>
          <FontAwesomeIcon size="lg" icon={['fal', 'circle-minus']} />
        </ListItemIcon>
        {t('menu.deleteAudience')}
      </MenuItem>
    </RowMenu>
  );
};

export const AudienceManagement = withSuspense()(() => {
  const { t } = useTranslation(['audience', 'translation']);
  const [, , putEvent] = useGA4();
  const [sendAuditEvent] = useAudit();
  const [selectedRow, setSelectedRow] = useState<AudienceListItem>();
  const [deletingAudience, setDeletingAudience] = useState<AudienceListItem | undefined>();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [editAudienceId, setEditAudienceId] = useState<number>();
  const [showAudienceDialog, setShowAudienceDialog] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [exportError, setExportError] = useState<MarApiError | undefined>();

  const [audiencesData, fetchAudiences, cleanFetchAudiences] = useAudiencesAPI();
  const [deletedAudienceData, fetchDeleteAudienceData, clearDeletedAudienceData] = useDeleteAudienceAPI(
    deletingAudience?.id
  );

  useEffect(() => {
    putEvent({ category: GA_EVENTS.audience.__name, action: GA_EVENTS.audience.view });
    sendAuditEvent({ action: AUDIT_EVENTS.audience.view });
    fetchAudiences();
    return cleanFetchAudiences;
  }, [cleanFetchAudiences, fetchAudiences, putEvent, sendAuditEvent]);

  useEffect(() => {
    if (deletedAudienceData.data) {
      setShowConfirmDialog(false);
      setDeletingAudience(undefined);
      fetchAudiences();
    }
    return clearDeletedAudienceData;
  }, [deletedAudienceData.data, clearDeletedAudienceData, fetchAudiences]);

  useEffect(() => {
    setSelectedRow(undefined);
  }, [audiencesData.data]);

  const onRowSelected = useCallback((row: AudienceListItem) => {
    setSelectedRow(row);
  }, []);

  const handleDelete = () => {
    fetchDeleteAudienceData();
  };

  const onDelete = useCallback((audience: AudienceListItem) => {
    setDeletingAudience(audience);
    setShowConfirmDialog(true);
  }, []);

  const onEdit = useCallback((id: number) => {
    setEditAudienceId(id);
    setShowAudienceDialog(true);
  }, []);

  const handleCloseDetailDialog = useCallback(() => {
    setShowAudienceDialog(false);
    setEditAudienceId(undefined);
  }, []);

  const columns = useMemo<HeadCell<AudienceListItem>[]>(
    () => [
      {
        title: t('fieldName.name'),
        dataIndex: 'name',
        render: (name, row) => (
          <Link
            component="button"
            underline="none"
            color="inherit"
            variant="body2"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(row.id);
            }}
          >
            {name}
          </Link>
        ),
      },
      {
        title: t('fieldName.scheduleUpdate'),
        dataIndex: 'schedules',
        render: ([nextSchedule] = [], { lastRun }) => (
          <>
            {nextSchedule?.nextFireTime
              ? t('scheduleUpdate.time', {
                  context: nextSchedule.frequency,
                  day: nextSchedule.day,
                  time: nextSchedule.time,
                })
              : t('scheduleUpdate.noSchedule')}
            {lastRun && (
              <>
                &nbsp;
                <Typography variant="body4">
                  ({t('translation:last')}&nbsp;
                  {t('dateTimeFormat.shortDate', {
                    val: moment(lastRun?.eventDate).toDate(),
                    ns: 'translation',
                  })}
                  )
                </Typography>
              </>
            )}
          </>
        ),
      },
      {
        title: t('fieldName.target'),
        dataIndex: 'schedules',
        render: (schedules) =>
          schedules.length ? schedules.map((item) => item.connectionName).join(', ') : t('translation:text.none'),
      },
      {
        title: '',
        dataIndex: 'id',
        align: 'right' as AlignTable,
        render: (__id, row) => (
          <AudienceActionMenu
            row={row}
            selectedRow={selectedRow}
            onEdit={onEdit}
            onDelete={onDelete}
            setExporting={setExporting}
            setExportError={setExportError}
          />
        ),
      },
    ],
    [onDelete, onEdit, selectedRow, t]
  );

  return (
    <ThemeProvider theme={themeLevel3}>
      <NewAudienceButton />
      <FallbackComponent overlay requesting={exporting}>
        <Grid container columnSpacing="4" flex={1} sx={{ overflow: 'hidden' }}>
          <Grid item xs={8} display="flex" flexDirection="column" gap={4} sx={{ height: '100%' }}>
            <ErrorMessageQueueBox errors={[audiencesData.error, exportError]} />
            <Card sx={{ flex: 1 }}>
              <CardHeader
                title={
                  <Button variant="contained" onClick={() => setShowAudienceDialog(true)}>
                    {t('addAudienceButton')}
                  </Button>
                }
                titleTruncate
                action={
                  <RowMenu>
                    <MenuItem component={Link}>some item</MenuItem>
                  </RowMenu>
                }
              />
              <CardContent sx={{ flex: 1, flexDirection: 'column' }}>
                <FallbackComponent requesting={!!audiencesData.requesting}>
                  <Table
                    columns={columns}
                    dataSources={audiencesData.data}
                    tableContainerProps={{ sx: { flex: 1, flexBasis: 0 } }}
                    tableProps={{ stickyHeader: true }}
                    selectedRow={selectedRow}
                    onRowClick={onRowSelected}
                  />
                </FallbackComponent>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} display="flex" flexDirection="column" gap={4} sx={{ height: '100%' }}>
            <AudienceHistoryTab key={selectedRow?.id} audience={selectedRow} />
          </Grid>
        </Grid>
      </FallbackComponent>
      <AudiencesDialog open={showAudienceDialog} onClose={handleCloseDetailDialog} audienceId={editAudienceId} />
      <ConfirmDialog
        title={t('deleteConfirmation.title')}
        errors={[deletedAudienceData.error]}
        confirmButtonRequesting={deletedAudienceData.requesting}
        message={t('deleteConfirmation.message', { name: deletingAudience?.name })}
        confirmButtonText={t('translation:button.delete')}
        abortButtonText={t('translation:button.cancel')}
        open={showConfirmDialog}
        onConfirm={handleDelete}
        onAbort={() => setShowConfirmDialog(false)}
      />
    </ThemeProvider>
  );
});
