import _ from 'lodash';
import React, { MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { usePageTitleState } from '../../../state/app';
import { usePageTitleAPI } from '../../pagetitleselect/selectors';
import { DateRangeFilter, FilterFormValues, ProspectCountPayload } from '../types';
import { getProspectCountPayload } from './constants';
import { useDefaultDateRangeFilter } from './hooks';
import { OneTimeScopeEnum } from './scheduledetails/enums';
import { useAudienceAPI, useFieldAPI, useProspectCountAPI } from './selectors';
import { useDefaultValue } from './useform';

interface AudienceProviderProps {
  audienceId?: number;
}

interface AudienceState {
  audienceId?: number;
  prospectCountPayloadRef: MutableRefObject<ProspectCountPayload | undefined>;
  refreshProspectCount: (values: FilterFormValues, dateRange: DateRangeFilter, hasError: boolean) => void;
  dateRange: DateRangeFilter;
  setDateRange: React.Dispatch<React.SetStateAction<DateRangeFilter>>;
}

const AudienceContext = React.createContext<AudienceState>({} as any);

export const useAudience = () => useContext(AudienceContext);

export const AudienceProvider = ({ audienceId, children }: React.PropsWithChildren<AudienceProviderProps>) => {
  const [audienceData, fetchAudienceData, clearAudienceData] = useAudienceAPI(audienceId);
  const [audienceFieldsData, fetchAudienceFields, clearAudienceFields] = useFieldAPI();
  const [, fetchProspectCount, clearProspectCount] = useProspectCountAPI();
  const [, fetchPageTitleData] = usePageTitleAPI();
  const [pageTitle] = usePageTitleState();
  const defaultDateRangeFilter = useDefaultDateRangeFilter();
  const [dateRange, setDateRange] = useState<DateRangeFilter>(defaultDateRangeFilter.current);
  const defaultFormValue = useDefaultValue(audienceData.data?.id);

  const initialFetchRef = useRef(true);
  const prospectCountPayloadRef = useRef<ProspectCountPayload>();

  useEffect(() => {
    fetchPageTitleData();
    fetchAudienceFields();
    fetchAudienceData();
    return () => {
      clearAudienceData();
      clearProspectCount();
    };
  }, [
    clearAudienceData,
    clearAudienceFields,
    clearProspectCount,
    fetchAudienceData,
    fetchAudienceFields,
    fetchPageTitleData,
  ]);

  useEffect(() => {
    if (!initialFetchRef.current || audienceFieldsData.requesting || (audienceId && !defaultFormValue.id)) return;
    const firstSchedule = _.get(audienceData.data?.schedules, '0');
    const dateRangeFilter = firstSchedule
      ? { scope: firstSchedule.scope, fromDate: firstSchedule.fromDate, toDate: firstSchedule.toDate }
      : {
        scope: OneTimeScopeEnum.CustomRange,
        fromDate: audienceData.data?.fromDate ?? defaultDateRangeFilter.current.fromDate,
        toDate: audienceData.data?.toDate ?? defaultDateRangeFilter.current.toDate,
      };
    const payload = getProspectCountPayload(defaultFormValue, dateRangeFilter);
    fetchProspectCount(payload);
    setDateRange(dateRangeFilter);
    prospectCountPayloadRef.current = payload;
    initialFetchRef.current = false;
  }, [
    defaultFormValue,
    pageTitle,
    fetchProspectCount,
    audienceData.data?.visitCategory,
    audienceId,
    audienceData.data,
    audienceFieldsData.requesting,
    defaultDateRangeFilter,
  ]);

  const refreshProspectCount = useCallback(
    (values: FilterFormValues, _daterange: DateRangeFilter, hasError: boolean) => {
      const countPayload = getProspectCountPayload(values, _daterange);
      if (!_.isEqual(countPayload, prospectCountPayloadRef.current) && !hasError) {
        fetchProspectCount(countPayload);
        prospectCountPayloadRef.current = countPayload;
      }
    },
    [fetchProspectCount]
  );

  const value = useMemo<AudienceState>(
    () => ({
      audienceId,
      prospectCountPayloadRef,
      refreshProspectCount,
      dateRange,
      setDateRange,
    }),
    [audienceId, refreshProspectCount, dateRange, setDateRange]
  );
  return <AudienceContext.Provider value={value}>{children}</AudienceContext.Provider>;
};
