import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DemographicType } from './selectors';

export const DemographicIcon: Record<DemographicType, IconProp> = {
  [DemographicType.Age]: ['fal', 'cake-candles'],
  [DemographicType.Gender]: ['fal', 'user-large'],
  [DemographicType.Income]: ['fal', 'money-bill-1-wave'],
  [DemographicType.Location]: ['fal', 'earth-americas'],
  [DemographicType.Married]: ['fal', 'rings-wedding'],
  [DemographicType.Homeowner]: ['fal', 'house-heart'],
  [DemographicType.Networth]: ['fal', 'sack-dollar'],
};
